import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const navLinks = [
  {
    title: "Home",
    url: "/#",
  },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "Download",
    url: "/download",
  },
  {
    title: "Classes",
    content: {
      menu: [
        {
          title: "Program Videos",
          url: "/class01",
          image: "/images/menu-video.svg",
        },
        {
          title: "Premium Class",
          url: "/class01",
          image: "/images/menu-class.svg",
        },
      ],
      links: [
        {
          title: "Boxing for Begginers",
          url: "/class01-details",
          image: "/images/content/avatar-2-full.png",
          image2x: "/images/content/avatar-2-full@2x.png",
          category: "black",
          categoryText: "featured class",
          avatar: "/images/content/avatar-2.png",
          trainer: "Javian Kiehn",
          content:
            "This class is a seven-day training program designed to boost your strength and your boxing skills over the course of a week.",
        },
        {
          title: "Cycling like a Pro",
          url: "/class01-details",
          image: "/images/content/cycling-1.png",
          image2x: "/images/content/cycling-1@2x.png",
          category: "red",
          categoryText: "fitness & gym",
          avatar: "/images/content/avatar-1.png",
          trainer: "Heather Johnson",
          level: "green",
          levelText: "beginner",
        },
        {
          title: "Sweet Salad",
          url: "/class01-details",
          image: "/images/content/salad-1.png",
          image2x: "/images/content/salad-1@2x.png",
          category: "green",
          categoryText: "cooking",
          avatar: "/images/content/avatar-3.png",
          trainer: "Jenna Hettinger",
        },
        {
          title: "Meditation",
          url: "/class01-details",
          image: "/images/content/header-pic-1.png",
          image2x: "/images/content/header-pic-1@2x.png",
          category: "red",
          categoryText: "yoga & meditation",
          avatar: "/images/content/avatar-4.png",
          trainer: "Rosemarie Hintz",
        },
      ],
      trainer: [
        {
          title: "Boyd Reinger",
          avatar: "/images/content/avatar-1.png",
          type: "Personal trainer",
        },
        {
          title: "Randal Jacobson",
          avatar: "/images/content/avatar-2.png",
          type: "Personal trainer",
        },
        {
          title: "Dwight Schamberger",
          avatar: "/images/content/avatar-3.png",
          type: "Personal trainer",
        },
        {
          title: "Omari Gulgowski",
          avatar: "/images/content/avatar-4.png",
          type: "Personal trainer",
        },
      ],
    },
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/brodtivision.com/",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/brodtivision",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/brodtivision/",
  },
];

const contact = [
  {
    title: "Montanachester",
    content: "06787 Block Estates",
  },
  {
    title: "Lake Gene",
    content: "167 Emard River",
  },
  {
    title: "North Hassiefort",
    content: "032 Leonora Spurs",
  },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.svg"
            srcDark="/images/logo-light.svg"
            alt="BrodTiVision"
          />
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.content ? (
                <DropdownMenu
                  className={styles.group}
                  item={x}
                  key={index}
                  setValue={setVisibleNav}
                />
              ) : (
                <NavLink
                  className={styles.link}
                  activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  onClick={() => setVisibleNav(false)}
                >
                  {x.title}
                </NavLink>
              )
            )}
          </nav>
          <div className={styles.details}>
            <div className={styles.contact}>
              {contact.map((x, index) => (
                <div className={styles.element} key={index}>
                  <div className={styles.category}>{x.title}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              ))}
            </div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={cn("button button-small button-stroke", styles.button)}
              href="https://www.brodtivision.com/for-companies/index.html"
            >
              Totem for Companies
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={cn("button button-small", styles.button)}
              href="https://brodtivision1.disciplemedia.com/login"
            >
              Register for Free
            </a>
          </div>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className={cn("button button-small button-stroke", styles.button)}
          href="https://www.brodtivision.com/for-companies/index.html"
        >
          Totem for Companies
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className={cn("button button-small", styles.button)}
          href="https://brodtivision1.disciplemedia.com/login"
        >
          Register for Free
        </a>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
