import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Intro.module.sass";
import ScrollParallax from "../ScrollParallax";

const Intro = ({ scrollToRef }) => {
  return (
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/cooking@2x.png 2x"
              src="/images/content/cooking.png"
              alt="Cooking"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h1", styles.title)}>
            All kinds of classes.
          </h2>
          <div className={styles.text}>
            From Yoga to Cooking, Health to Boxing find a class that matches your needs
          </div>
          <a className={cn("button", styles.button)} href="https://brodtivision1.disciplemedia.com/login" target="_blank">
            Register now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
