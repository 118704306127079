import React, { useRef } from "react";
import styles from "./Download.module.sass";
import Hero from "./Hero";
import Platform from "./Platform";
import Community from "../../components/Community";


const Download = () => {
  const scrollToRef = useRef(null);
  return (
    <>
      <Hero scrollToRef={scrollToRef} />
      <Platform scrollToRef={scrollToRef} />
      <Community />
    </>
  );
};

export default Download;
