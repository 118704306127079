import React from "react";
import cn from "classnames";
import styles from "./About.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Intuitive and clean design",
    content:
      "We have  a very simple app, easy to navigate and interact",
    color: "#9757D7",
  },
  {
    title: "Easy to join, easy to watch",
    content:
      "Subscribe to your favorite classes groups and enjoy the daily lives.",
    color: "#EF466F",
  },
  {
    title: "The power of the Community",
    content:
      "Join the discussion groups to share your thoughts, ask for help and learn from others.",
    color: "#45B26B",
  },
];

const About = () => {
  return (
    <div className={cn("section-border-top", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("stage", styles.stage)}>BrodTiVision</div>
          </div>
          <div className={styles.col}>
            <h2 className={cn("h2", styles.title)}>
            The power of the <br></br>Comunnity
            </h2>
            <div className={styles.info}>
              Join your favorite classes, watch our daily lives, access exclusive content and enjoy the community forums
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <div
                    className={styles.number}
                    style={{ backgroundColor: x.color }}
                  >
                    0{index + 1}
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </ScrollParallax>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.bg}>
              <ScrollParallax className={styles.preview} animateIn="fadeInUp">
              <img
                srcSet="/images/content/planet@2x.png 2x"
                src="/images/content/planet.png"
                alt="Planet"
              />
              </ScrollParallax>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
