import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
// import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Home",
    url: "/#",
  },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "Classes",
    url: "/class01",
  },
  /*{
    title: "Be a Pro Teacher",
    url: "/features",
  },*/
  {
    title: "Download",
    url: "/download",
  },
];

const externalLinks = [
  {
    title: "Totem for companies",
    url: "http://investors.brodtivision.com/",
    target: "_blank"
  },
];

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/brodtivision.com/",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/brodtivision",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/brodtivision/",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <Image
                  className={styles.pic}
                  src="/images/logo-icon-dark.svg"
                  srcDark="/images/logo-icon-light.svg"
                  alt="BrodTiVision"
                />
              </Link>
              {/* <Theme className={styles.theme} /> */}
            </div>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                footer nav
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
                {externalLinks.map((x, index) => (
                  <a
                    className={styles.link}
                    activeClassName={styles.active}
                    href={x.url}
                    target={x.target}
                    key={index}
                  >
                    {x.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>contact</div>
            <div className={styles.info}>
              <p>99 Wall Street #4042</p>
              <p>New York, NY</p>
              <p>United States</p>
              <p>(917) 2311465</p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>newsletter</div>
            <div className={styles.info}>
              Subscribe our newsletter and receive weekly updates of our classes and lives.
            </div>
            <Subscription
              className={styles.subscription}
              placeholder="Enter your email"
            />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2022 BrodTiVision. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
