import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Workouts.module.sass";
import Image from "../Image";
import ScrollParallax from "../ScrollParallax";

const items = [
  "Premium Badge",
  "Access to Private Groups",
  "Access to Previous Lessons",
  "Access to Exclusive Lessons",
  "And much more!",
];

const Workouts = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/hand-phone@2x.png 2x"
              srcSetDark="/images/content/phones-dark@2x.png 2x"
              src="/images/content/hand-phone.png"
              srcDark="/images/content/phones-dark.png"
              alt="Phones"
            />
          </div>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/gloves@2x.png 2x"
              src="/images/content/gloves.png"
              alt="Gloves"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/bottle@2x.png 2x"
              src="/images/content/bottle.png"
              alt="Bottle"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/heart-2@2x.png 2x"
              src="/images/content/heart-2.png"
              alt="Bottle"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/education@2x.png 2x"
              src="/images/content/education.png"
              alt="Bottle"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
          Workouts & Lessons <br></br>in your hands
          </h2>
          <div className={styles.info}>
            Want more? As a premium user you have access to exclusive contents, groups and more!
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
          <div className={styles.btns}>
            <a href="https://brodtivision1.disciplemedia.com/login" target="_blank" className={cn("button", styles.button)}>
              Subscribe now
            </a>
            {/* <button className={cn("button-stroke", styles.button)}>
              Start as a free member
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
