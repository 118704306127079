import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Register for Free",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "You can use our web version or download the mobile app, available for iOS and Android",
  },
  {
    title: "Choose your classes",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "You can choose as many as you want! We have teachers streaming classes everyday",
  },
  {
    title: "Discuss",
    color: "#EF466F",
    images: "/images/content/comment.svg",
    content:
      "We have discussion groups to talk about the lessons. You can share your thoughts or ask for help",
  },
  {
    title: "Want more?",
    color: "#45B26B",
    images: "/images/content/medal.svg",
    content:
      "Premium Users can access special content and other benefits!",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            BrodTiVision is a simple community app that helps you to learn, train and teach basically anything.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
